.leads-heading {
  font-size: 1.2rem;
  margin: 0.5rem;
  font-weight: bold;
  text-align: center;
}
.leads-heading button{
  margin-left: 150px;
}
.leadsList-container {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  overflow-y: scroll;
  align-items: center;
  margin: auto;
}
.leadsList-card {
  width: calc(100% - 32px);
  margin: 0.5rem auto;
  border-radius: 0.4rem;
  padding: 0.6rem;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
