.webcamFooterContainer {
  background: black;
  width: 100%;
  max-width: 720px;
  height: 80px;
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 99999;
  bottom: 0;
}
.webcamButton {
  border-radius: 6px;
  background: yellow;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.25);
  width: fit-content;
  min-width: 90px;
  /* padding: 0.8rem 1.2rem; */
  color: #0a0d45;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 112.5% */
  border: none;
  display: block;
  height: 44px;
  cursor: pointer;
}
.innerContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 0.5rem;
}
