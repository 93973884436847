.upload-page {
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.upload-page h5 {
  color: #333;
  margin-bottom: 20px;
  font-size: 24px;
}
.upload-page h5 {
  color: #333;
  margin: 0.3rem;
  font-size: 1.2rem;
}

.dropdown-container {
  margin-bottom: 20px;
}

.dropdown-container label {
  font-size: 18px;
  color: #555;
  margin-right: 10px;
}

.dropdown-container select {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}
.dropdown-container label,
.dropdown-container select {
  width: 100%;
  text-align: left;
  display: block;
  margin-bottom: 10px;
}

.image-container {
  margin-top: 20px;
}

.image-container h2 {
  margin-bottom: 10px;
  font-size: 20px;
  color: #333;
}

.sample-image {
  max-width: 250px;
  max-height: 200px;
  object-fit: contain;
  height: auto;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.uploaded-toggle-container {
  width: 100%;
  max-width: 400px;
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  margin: 1rem auto;
}
.toggle-item {
  width: 50%;
  text-align: center;
  background: white;
  color: black;
  padding: 0.6rem 1rem;
  cursor: pointer;
}
.toggle-item-active {
  width: 50%;
  text-align: center;
  background: green;
  color: white;
  padding: 0.6rem 1rem;
  cursor: pointer;
}
@media (max-width: 768px) {
  .upload-page {
    margin: 20px;
    padding: 15px;
  }

  .dropdown-container label,
  .dropdown-container select {
    width: 100%;
    text-align: left;
    display: block;
    margin-bottom: 10px;
  }
}

.btnDisabled, .btnDisabled:hover{
  background-color: grey;
}
