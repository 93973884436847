/* QRCode.css */
.qr-code-generator {
    margin: 20px;
  }
  
  .qr-code-generator label {
    margin-right: 10px;
  }
  
  .qr-code-generator input {
    padding: 5px;
  }
  
  .qr-code-generator button {
    padding: 5px 10px;
    margin-top: 10px;
    cursor: pointer;
  }
  .mytxt{
    width: 100%;
  padding: 8px;
  box-sizing: border-box;
  }