.leads-heading {
  font-size: 1.2rem;
  margin: 0.5rem;
  font-weight: bold;
  text-align: center;
}
.leadsDetails-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}
.leadsDetails-container {
     display: flex;
    flex-direction: column;
    max-width: 400px;
    overflow-y: scroll;
    align-items: center;
    margin: auto;
    width: calc(100% - 1rem);
}
.leadsDetails-card {
  width: calc(100% - 32px);
  margin: 0.5rem auto;
  border-radius: 0.4rem;
  padding: 0.6rem;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.input-group {
  margin-bottom: 15px;

}

.input-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.input-group input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.input-border {
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none;
}

.custom-button {
  max-width: 300px;
  margin: auto;
  padding: 10px;
  background-color: green;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: green;
}
