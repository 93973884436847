.login-container {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.input-group {
  margin-bottom: 15px;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.input-group input {
  width: 100%;
  padding: .5rem;
  font-size: 1rem;
  box-sizing: border-box;
}

.input-border {
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none;
}

.password-wrapper {
  position: relative;
}

.password-wrapper input {
  width: calc(100% - 30px);
  border: none;
  outline: none;
}

.password-toggle {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

form {
  display: flex;
  flex-direction: column;
  gap: .7rem;
}

.custom-button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #0056b3;
}
